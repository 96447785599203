<template>
  <div id="nav">
    <div class="relative constrain flex items-center text-sm">
      <Go
        :to="'/' + $store.state.router.locale"
        aria-label="Home"
        class="mr-auto hidden md:block"
      >
        <Logo class="logo" />
      </Go>
      <nav class="inner">
        <div class="flex">
          <Go
            v-for="link of navigation?.links"
            :key="link.id"
            :to="link.uri"
            class="link"
            :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
            v-text="link.title"
          />
        </div>
        <div id="subnav" class="relative" />
      </nav>
    </div>

  </div>
</template>

<script>
import Logo from '@/assets/images/logo.svg';

export default {
  components: {
    Logo,
  },
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
  },
};
</script>

<style lang="scss" scoped>
#nav {
  position: absolute;
  z-index: 100;
  width: 100vw;
  padding-top: 50px;

  .logo {
    width: 200px;
    @screen lg {
      width: 340px;
    }
  }

  .inner {
    position: relative;
    height: 2rem;
  }

  @media (max-width: theme("screens.md")) {
    padding-top: 20px;
    .inner {
      position: fixed;
      top: auto;
      height: auto;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.3em;
    }
  }
}

.link {
  background: var(--color-primary-light);
  backdrop-filter: blur(12px);
  padding: 0.3em 2.3em;
  color: white;
  text-transform: uppercase;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.2));
  transition: background 0.3s ease;
  white-space: nowrap;
  letter-spacing: 1px;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.active {
    background: var(--color-primary);
  }

  &:hover {
    background: rgba(215, 97, 43, 0.8);
  }

  @media (max-width: theme("screens.md")) {
    font-size: .8em;
    padding: 0.2em 2em;
  }
}
</style>
