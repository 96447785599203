<template>

  <transition-group name="fade">
    <component
      :is="component.component"
      v-for="component of inject"
      :id="component.slug || component.data.slug"
      :key="component.id || component.data.id"
      :payload="component.data || component"
      class="fade-item"
    />
  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

const NotFound = defineAsyncComponent(() => import('@/components/structure/NotFound.vue'));

const Slider = defineAsyncComponent(() => import('@/components/pages/Slider.vue'));

export default {
  components: {

    NotFound,

    Slider,

  },
  props: { inject: { type: Object, default: Object } },
};
</script>
