<template>

  <svg xmlns="http://www.w3.org/2000/svg" class="absolute">
    <defs>
      <clipPath id="cutout-big" clipPathUnits="objectBoundingBox">
        <path
          d="M.10116.0132C.10341.00517.10844,0,.114,0H.98645c.00776,0,.014.01.014.02222V.61891l-.0012.009L.89884.98678C.89659.99482.89155,1,.886,1H.01355c-.00776,0-.014-.01-.014-.02222V.37972l.00121-.009Z"
        />
      </clipPath>
      <clipPath id="cutout-small" clipPathUnits="objectBoundingBox">
        <path
          d="M.17546.02286C.1793.009.188.00006.19758,0H.97575C.98914,0,1,.01706,1,.0381v.311A.051.051,0,0,1,.99788.36429L.82454.97714C.8207.991.812.99994.80242,1H.02425C.01086,1,0,.98294,0,.9619V.651A.051.051,0,0,1,.00212.63571Z"
        />
      </clipPath>
      <clipPath id="cutout-open" clipPathUnits="objectBoundingBox">
        <path
          d="M1,.98417A.02326.02326,0,0,1,.97536,1L.02425.99762C.01173.99749.0016.98158.00152.9619V.651A.05251.05251,0,0,1,.00334.63667L.17667.02381C.18034.01082.18853.00243.19758.00238H.97575C.98827.00251.9984.01842.99848.0381"
        />
      </clipPath>
      <clipPath id="cutout-mini" clipPathUnits="objectBoundingBox">
        <path
          d="M.263.03523C.26927.01381.28336,0,.29892,0H.96063c.02845,0,.04748.04411.036.08329L.737.96477C.73073.98619.71664,1,.70109,1H.03937C.01092,1-.00811.95589.00342.91671Z"
        />
      </clipPath>
    </defs>
  </svg>

  <div id="overlay" />

  <Navigation />

  <main>
    <Dynamic v-if="content" :inject="content" />
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/composables/dynamicPage';

export default {
  components: {
    Navigation,
    Dynamic,
  },

  setup() {
    dynamicPage();
  },

  computed: {
    content() {
      return this.$store.state.router.page?.content;
    },
  },

};
</script>
