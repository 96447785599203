import { createApp } from 'vue';
import { createHead } from '@vueuse/head';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Image from '@/components/utils/Image.vue';

import store from './store';
import './assets/styles/main.scss';

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);

app.use(store);

window.getPath = () => window.location.pathname.replace(/\/$/, '');
window[atob('amt3ZWI=')] = atob('Tm8sIHRoaXMgaXMgUGF0cmljayE');

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);

app.mount('#app');
