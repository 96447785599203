<template>
  <span v-if="!src" />
  <span v-else-if="src.svg" :alt="src.alt" v-html="src.svg" />
  <img
    v-else
    ref="image"
    :alt="src.alt"
    :loading="lazy ? 'lazy' : 'auto'"
    :srcset="`${src.small} 600w,
             ${src.normal} 940w,
             ${src.large} 1500w`"
    sizes="(max-width: 600px) 600px,
    (max-width: 940px) 940px,
    1500px"
    :src="src.small"
  >
</template>

<script>
export default {
  props: {
    src: { type: Object, default: null },
    lazy: { type: Boolean, default: true },
  },
};
</script>
